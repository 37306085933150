import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import Content from "src/components/Content"

import "./locationTemplate.scss"

const LocationTemplate = ({data: {page: {frontmatter: {
  meta, hero, main, pictures}
}}}) => (
  <Layout meta={meta}>
    <div className="location-template">
      <PageHero
        image={hero.image}
        className="whole-page"
        overlay={false}
      />
      <section className="location-detail">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h1>{main.title}</h1>
              <Content html={main.description} />
            </div>
          </div>
        </div>
      </section>

      <section className="location-pictures">
        <div className="container">
          <div className="image-grid">
            {pictures.map((picture, index) => (
              <figure key={picture.attribution} className={`image${index + 1}`}>
                <GatsbyImage
                  image={picture.image.childImageSharp.gatsbyImageData}
                  alt={`${main.title} ${index}`} />
                {picture.attribution && (
                  <figcaption>
                    <a 
                      href={picture.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`Photo by ${picture.attribution}`}
                    </a>
                  </figcaption>
                )}
              </figure>
            ))}
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default LocationTemplate

export const pageQuery = graphql`query ($path: String!) {
  page: markdownRemark(frontmatter: {meta: {path: {eq: $path}}}) {
    html
    ...MetaFragment
    ...HeroFragment
    frontmatter {
      main {
        title
        description
      }
      pictures {
        image {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
        attribution
        link
      }
    }
  }
}
`
